import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/dash/layout'
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import Popups from '../components/popup';
import axios from 'axios'

function Settings() {

  var code2 = sessionStorage.getItem("token")
  const decoded = jwt_decode(code2)
  const shopname = decoded.jwttoken;

  const [user, setUser] = useState({
    shop: shopname,
    popupheading: "",
    inputplaceholder: "",
    buttontext: "",
    backcolor: "",
    buttoncolor: "",
    buttoncolor2: "",
    buttoncolor3: "",
    togglebutton1: "",
    togglebutton2: "",
    multipassToken: ""
  })

  const [isSwitchOn, setSwitchOn] = useState('');
  const [isSwitchOn2, setSwitchOn2] = useState(null);

  const [planstatus ,setPlanStatus] = useState("")

  useEffect(()=>{
      getPlanDetails()
    },[])
  
  
    const getPlanDetails = async() =>{
      const payload = {
        "shop" : shopname,
      }
       try{
        const response = await axios({
          url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
          method:"POST",
          headers:{
            "Content-Type": "application/json",
          },
          data:payload
        })
        if(response){
          console.log("respppppppppp",response.data.planName)
          setPlanStatus(response.data.planName)
        }
       }catch(error){
        console.log(error)
       }
    }



  const handleSwitchToggle = () => {
    let type;
    if (isSwitchOn === "enable") {
      setSwitchOn("disable");
      type = "disable"
    } else if (isSwitchOn === "disable") {
      setSwitchOn("enable");
      type = "enable"
    }

    const payload = {
      shop: shopname,
      togglebutton1: type,
    };


    fetch('https://otplogin.ens.enterprises/data/otp/toggle', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        toast.success("Toggle updated successfully", {
          autoClose: 1500
        })
      })
      .catch(error => {
        console.error('Error:', error);
      });
    console.log("final state-====>", isSwitchOn)
  }

  const handleSwitchToggle2 = () => {
    let value;
    if (isSwitchOn2 == "disable") {
      setSwitchOn2("enable");
      value = "enable"
    }
    if (isSwitchOn2 == "enable") {
      setSwitchOn2("disable");
      value = "disable"
    }
    const payload = {
      shop: shopname,
      togglebutton2: value,
    };

    fetch('https://otplogin.ens.enterprises/data/otp/toggle', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        toast.success("Toggle updated successfully", {
          autoClose: 1500
        })
      })
      .catch(error => {
        console.error('Error:', error);
      });

  }



  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    const payload = {
      shop: shopname
    }
    try {

      const response = await fetch("https://otplogin.ens.enterprises/data/get/shop", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setData(data);
      setUser(data)
      console.log("Setting Data------------------------>", data)
      setSwitchOn(data.togglebutton1)
      setSwitchOn2(data.togglebutton2)
    } catch (error) {
      setError(error.message);
    }
  };



  return (
    <div>
      <Layout>
       {
        (planstatus === "Free trial" || planstatus === "Basic" ) ? (
          <div className='container ' style={{ background: "#eae8f0" }}>
          <div className='row p-4 '>
            <div className=' col-lg-6 col-md-6 col-sm-12 card setting_page card-body rounded-lg'>
              <h4 className='mb-4' style={{color:"rgb(122, 220, 180)"}} >Setting Page</h4>
              <div className=''>
                <label className="switch">
                <input type="checkbox" checked={(isSwitchOn == "enable") ? true : false} onChange={handleSwitchToggle} />
                <span className="slider round"></span>  
                </label>
                <div>
                  <h5 style={{ margin: "5px 0px",color:"rgb(122, 220, 180)" }} >OTP button</h5>
                </div>
              </div>
              <div >
                <label className="switch">
                <input type="checkbox" checked={(isSwitchOn2 == "enable") ? true : false} onChange={handleSwitchToggle2} />
                <span className="slider round"></span>
                </label>
                <div>
                  <h5 style={{ margin: "5px 0px", color:"rgb(122, 220, 180)" }}  >Social button</h5>
                </div>
              </div>
            </div>
            <div className=' col-lg-6 col-md-6 col-sm-12'>
              <Popups arg={{ user, setUser }} />
            </div>
          </div>
        </div>
        ) :
        (!planstatus) ?  ( <h5 style={{textAlign:"center"}} >Loading...</h5> ) :
        (
          <div className='userDiv'><h2>This is only accessed by our paid users.</h2></div>
        )
       }
       
        <ToastContainer />
        <Toaster />
      </Layout>
    </div>
  )
}

export default Settings