import config from "../../utils/config";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
//import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';
const { apikey, scopes, redirecturl } = config;


const UrlPage = ({inputValue}) => {
    const [searchParams] = useSearchParams();
    const shop = searchParams.get("shop");
    const hmac = searchParams.get("hmac") || "984294fhsjkdfkjdsfk";

 
    const oAuthUrl = `https://${shop ? shop : inputValue}/admin/oauth/authorize?client_id=${apikey}&scope=${scopes}&redirect_uri=${redirecturl}&state=${hmac}`;
    window.location.href = oAuthUrl;
        
    return (
        <div className="lodingimg">
             <img src='asset/Spinner.gif'/>
        </div>
    );
}

export default UrlPage;