import React from 'react'
import Layout from './layout'
import sms from "../../components/dash/images/sms.png";
import social from "../../components/dash/images/social.png";
import google from "../../components/dash/images/google1.png";
import facebook1 from '../../components/dash/images/facebookAppDomain.png'
import facebook2 from "../../components/dash/images/facebookRedirect.png";
import themeapp from "../dash/images/themeapp.jpg";
import multipass from "../dash/images/mult.jpg";

const Document = () => {
  return (
    <>
    <Layout>
    <div className="container" style={{ background: "#eae8f0" }}>
        <div className="divStyle custom_css m-2">
          <span>
            {" "}
            <h5><b>Smart OTP and Social Login App</b></h5>{" "}
          </span>
          <p>
            {" "}
            The app is designed for all the Shopify Basic and  Plus users · Once you
            install it, it will be available on your admin panel.{" "}
          </p>
          <br></br>
          <br></br>

          <span>
            <h6><b>How it works</b></h6>
          </span>
          <p>
            · On the appearance page, provides the multipass token which is
            available on Shopify store. There is a link for get the Multipass Token for your reference,you can see the example screeshot.
          </p>
          <br></br>
          <span>
            <img src= {multipass} style={{ width:"100%"}} />
          </span>

          <span>
            <h6>Setting</h6>
          </span>
          <p>
            · We provide 2 toggle buttons for OTP log-in and social login, the
            pop-up will be reflected accordingly on UI.
          </p>
          <br></br>

          <span>
            <h6> Plan</h6>
          </span>
          <p>
            · This feature includes charges 9.99$ annually, once approved, the
            plan will be activated.This app contains only free trial for 7 days after that it will ask to activate your basic plan for $9.99/month.
            It doesn't contain any free plan.
          </p>
          <br></br>

          <span>
            <h6>Integration</h6>
            <img src={sms} style={{ width: "100%" }} />
          </span>
          <p>
            · We provide 2 settings integration for SMS and social login SMS
            Setting Integration · Fast2sms, will require an API key. · Gupshup,
            User ID, password, and body template will be required.
          </p>
          <br></br>

          <span>
            <h6>Social Login</h6>
            <img src={social} style={{ width: "100%" }} />
          </span>
          <p>
            · For Google and Facebook log-in, client id and client secret will be required. 
            <br/>
            • Here is the step for Google log-in:
            <br/>
            Go to Google Cloud &#8594; Create a Project &#8594;Enter the URLs as mentioned in the below screenshot.
              <br/>
            • For facebook log-in:
            <br/>
             Go to meta developer &#8594;Create a project &#8594;Enter the URLs as mentioned in the below screenshot.
          </p>
          <br></br>


          <h3>Google Redirect</h3>
          <img src={google} style={{ width: "60%" }} />
          <div style={{ margin: "3px 0px" }}>
            <p>
              Please ensure that you use the authorized redirect URI ({" "}
              <b>
                https://otplogin.ens.enterprises/auth/google/callback
              </b>{" "}
              ) as it is mandatory for securely completing the google login
              process.
            </p>
          </div>


          <h3 >Facebook Redirect</h3>
          <div >
          <img src={facebook1} style={{ width: "100%" }} />      
          </div>
            <div>
          <img src={facebook2} style={{ width: "100%",height:"100%",margin:"10px 0px" }} />
          </div>
          <div style={{ margin: "10px 0px" }}>
            <p>
              Please ensure that you use the authorized redirect URI ({" "}
              <b>
                https://otplogin.ens.enterprises/auth/facebook/callback
              </b>{" "}
              ) and <b>App domain</b>  as it is mandatory for securely completing the facebook login
              process.
            </p>
          </div>

          <div>
            <h3>
             <b>we have activated the theme app extension.</b> 
            </h3>
            <img src={themeapp} style={{ width: "100%",height:"100%" }} />
          </div>

          <span>
            <h6>Support</h6>
          </span>
          <p>· We are available on our chat support.</p>
          <br></br>``
        </div>
      </div>
      </Layout>
    </>
  )
}

export default Document
