import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import Layout from './layout';
import jwt_decode from "jwt-decode";
import Popups from '../popup';
import axios from 'axios';
import { Link } from 'react-router-dom';


function Main() {

  var code = sessionStorage.getItem("token")
  const decoded = jwt_decode(code)
  const shopname = decoded.jwttoken;
  //console.log("shopname===>",shopname)
  const multitokenshop = shopname.split(".myshopify.com")[0]

  const [user, setUser] = useState({
    shop: shopname,
    popupheading: "",
    inputplaceholder: "",
    buttontext: "",
    backcolor: "",
    buttoncolor: "",
    buttoncolor2: "",
    buttoncolor3: "",
    togglebutton1: "",
    togglebutton2: "",
    multipassToken:"",
    GoogleText:"",
    FBText:""
  })


  const [planstatus ,setPlanStatus] = useState("")

  useEffect(()=>{
      getPlanDetails()
    },[])
  
  
    const getPlanDetails = async() =>{
      const payload = {
        "shop" : shopname,
      }
       try{
        const response = await axios({
          url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
          method:"POST",
          headers:{
            "Content-Type": "application/json",
          },
          data:payload
        })
        if(response){
          console.log("respppppppppp",response.data.planName)
          setPlanStatus(response.data.planName)
        }
       }catch(error){
        console.log(error)
       }
    }

  const notify1 = () => {
    console.log("mmmmmm=====>>", "formsubmit");
   
  };

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    const payload = {
      shop: shopname
    }
    try {

      const response = await fetch("https://otplogin.ens.enterprises/data/get/shop", {


        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUser(data);
      // console.log("Dashboard data------------------------>",user)
    } catch (error) {
      setError(error.message);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://otplogin.ens.enterprises/data/otp/settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user)
      });
      if(response.status===200){
        toast.success("Saved Successfully",{
          autoClose:1500
        })
      }

      if (!response.ok) {
        console.log("Error")
      }
      // const data = await response.json();
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div  >
      <Layout>
        {
          (planstatus === "Free trial" || planstatus === "Basic" ) ? (
            <div className='container p-4 MainDiv' style={{ background: "#eae8f0"}}>
            <div className="row">
                 <form onSubmit={(e) => { handleSubmit(e) }} className="needs-validation col-lg-6 col-md-6 col-sm-12" novalidate>
                   <div className="sub-container">
                     <h2 className='form-heading'>OTP Validator</h2>
                     <p>You can use OTP Validator by filling these inputs.</p>
     
                     {/* <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                       <input type="text" className="form-control" name="popupheading" id="validationCustom011" placeholder="Change the heading of pop-up"
                         onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.popupheading}
     
                         required />
                     </div>
     
                     <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                       <input type="text" className="form-control" name="inputplaceholder" id="validationCustom011" placeholder="Change the placeholder of input"
                         onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.inputplaceholder}
                         required />
                     </div>
     
                     <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                       <input type="text" className="form-control text-dark" name='buttontext' id="validationCustom011" placeholder="Change the button text"
                         onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttontext}
                         required />
                     </div>
     
     
                     <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                       <input type="text" className="form-control text-dark" name='GoogleText' id="validationCustom011" placeholder="Change Google Text"
                         onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.GoogleText}
                         required />
                     </div>
     
     
                     <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                       <input type="text" className="form-control text-dark" name='FBText' id="validationCustom011" placeholder="Change Facebook Text"
                         onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.FBText}
                         required />
                     </div> */}
     
     
     
     
     
     
     
     
                     {/* <div className="col-lg-12 col-md-12 col-sm-12">
                       <div className="d-flex btn-textcolor justify-content-between">
                         <label for="exampleInputurl" className="form-label picker-class">Change pop-up background color:</label>
                         <div className="color-pick">
                           <input type="color" id="exampleInputurl" name="backcolor"
                             onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.backcolor}
                           />
                         </div>
                       </div>
                         </div>
     
     
     
                     <div className="col-lg-12 col-md-12 col-sm-12">
                       <div className="d-flex btn-textcolor justify-content-between">
                         <label for="exampleInputurlb" className="form-label picker-class">Change button color:</label>
                         <div className="color-pick">
                           <input type="color" id="exampleInputurlb" name="buttoncolor"
                             onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttoncolor}
                           />
                         </div>
                       </div></div>
     
                     <div className="col-lg-12 col-md-12 col-sm-12">
                       <div className="d-flex btn-textcolor justify-content-between">
                         <label for="exampleInputurlb" className="form-label picker-class">Change google color:</label>
                         <div className="color-pick">
                           <input type="color" id="exampleInputurlb" name="buttoncolor2"
                             onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttoncolor2}
                           />
                         </div>
                       </div></div>
     
                     <div className="col-lg-12 col-md-12 col-sm-12">
                       <div className="d-flex btn-textcolor justify-content-between">
                         <label for="exampleInputurlb" className="form-label picker-class">Change facebook color:</label>
                         <div className="color-pick">
                           <input type="color" id="exampleInputurlb" name="buttoncolor3"
                             onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttoncolor3}
                           />
                         </div>
                       </div>
                     </div> */}
     
                     <div className="col-lg-12 col-md-12 col-sm-12"
                     // for="validationCustom011" htmlFor="formGroupExampleInput"
                     >
                       <input type="text"
                         className="form-control"
                         name="multipassToken" id="validationCustom011" 
                         placeholder="Multipass Token"
                         onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.multipassToken} 
     
                         required />
                     </div>
     
     
     
                     <div>
                       <button type="submit" className="btn btn-success" id='btn-style'
                         onClick={(e) => { notify1(e) }}>
                         Success
                       </button>
                       <ToastContainer/>
                     </div>
                     <p style={{fontWeight:"bold",fontSize:"12px", marginTop:"10px"}}>Click on <Link to={`https://admin.shopify.com/store/${multitokenshop}/settings/customer_accounts`} target='_blank' > Multipass token  </Link>  key and enter the token and save it.  </p>
                   </div>
                 </form>
                
                 <div  className='col-lg-6 col-md-6 col-sm-12'>
                 <Popups arg={{user, setUser}}  />
                 </div>
               </div>
            </div>
          ) : 
          (!planstatus) ?  ( <h5 style={{textAlign:"center"}} >Loading...</h5> ) :
          (
            <div className='userDiv' ><h2>This is only accessed by our paid users.</h2></div>
          )
        }
      
      </Layout>
    </div>
  )
}

export default Main;