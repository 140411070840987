import React, {useState , useEffect} from 'react'
import Layout from '../components/dash/layout'
import './report.css'
import axios from 'axios'
import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

function Report() {
  const[planStatus, setPlanStatus] = useState('')
  let [searchParams] = useSearchParams();
  const code = searchParams.get("code") || sessionStorage.getItem("token")
  if(code){
  sessionStorage.setItem("token",code)
  }

  
  const decoded = jwt_decode(code)
  const shopname = decoded.jwttoken;


  useEffect(()=>{
    getPlanDetails()
  },[])


  const getPlanDetails = async() =>{
    const payload = {
      "shop" : shopname,
    }
     try{
      const response = await axios({
        url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
        method:"POST",
        headers:{
          "Content-Type": "application/json",
        },
        data:payload
      })
      if(response){
        console.log("respppppppppp",response.data.planName)
        setPlanStatus(response.data.planName)
      }
     }catch(error){
      console.log(error)
     }
  }


  const planHandler = async () => {

    const payload = {
      "shop": shopname,
      "planValue": "2"
    }
    try {
      const response = await axios({

        url: `https://otplogin.ens.enterprises/OTPplan/plan/create`,

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: payload
      })
      if (response) {
        window.location.href = response.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (

    <Layout>


      <div className='container p-4  ' style={{ background: "#eae8f0" }}>

       
          <div className="main">

          </div>
          <div className="box">

            <div className="box1">
              <h5 className="heading5" style={{ margin: "1.5rem 4rem", }}>
                Basic Plan
              </h5>
              <p className="para">$9.99 / month</p>
              <p style={{ textAlign: "center", fontWeight: "500" }}>
                <span style={{ marginRight: "3%" }}>
                  {/* <img src="./asset/tick2.png" alt="tick1" /> */}
                </span>
                Edit message text
              </p>
              <p style={{ textAlign: "center", fontWeight: "500" }}>
                <span style={{ marginRight: "3%" }}>
                  {/* <img src="./asset/tick2.png" alt="tick1" /> */}
                </span>
                Tiny order status URL
              </p>
              <p style={{ textAlign: "center", fontWeight: "500" }}>
                <span style={{ marginRight: "3%" }}>
                  {/* <img src="./asset/tick2.png" alt="cross" /> */}
                </span>
                Unlimited Notification
              </p>
             {
              ( planStatus=="Basic" ) ? (<button disabled 
                className="btn22 btn btn-success"
              >
                Activate
              </button> )  : 
              (
                <button onClick={() => planHandler()}
                className="btn22 btn btn-success"
              >
                Activate
              </button>
              )
             }
              
            </div>

          </div>

        
      </div>

    </Layout>

  )
}

export default Report