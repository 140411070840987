import React, { useState , useEffect } from 'react'
import { Link } from 'react-router-dom';
import image from '../dash/images/ens.jpg';
import axios from 'axios' ;
import jwt_decode from "jwt-decode"

function Header() {

  const[planStatus, setPlanStatus] = useState('')

  var headerToken = sessionStorage.getItem("token")
  const decoded = jwt_decode(headerToken)
  const shopname = decoded.jwttoken;

  const planHandler = async() =>{
    const payload = {
      "shop" : shopname,
       "planValue": "2"
    }
   try{
      const response = await axios({

          url:`https://otplogin.ens.enterprises/OTPplan/plan/create`,

          method:"POST",
          headers:{
            "Content-Type": "application/json",
          },
          data: payload
      })
      if(response){
          window.location.href = response.data 
      }
   }catch(error){
      console.log(error)
   }
  } 


  useEffect(()=>{
    getPlanDetails()
  },[])


  const getPlanDetails = async() =>{
    const payload = {
      "shop" : shopname,
    }
     try{
      const response = await axios({
        url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
        method:"POST",
        headers:{
          "Content-Type": "application/json",
        },
        data:payload
      })
      if(response){
        console.log("respppppppppp",response.data.planName)
        setPlanStatus(response.data.planName)
      }
     }catch(error){
      console.log(error)
     }
  }
  
    
    
    return (
        <div>
            <div className='p-3 d-flex justify-content-between' style={{background:"#f6f6f7"}}>
                <div className='fw-bold text-light'> 
                <Link to={"/dashboard"}
                  style={{textDecoration:"none", color:"white"}}
                >  <img className='mt-2 ms-3' src={image} style={{width:"100px" , height:"70px"}} /> 
                </Link> 
                </div>
                
                <div style={{display:"flex",alignItems:"center"}} >
                  <span> Your current Plan :  <strong style={{color:"#29845A"}} > {planStatus}</strong> </span>
                  {
                    (planStatus=="Basic") ? (<button className='btn btn-success m-3'  disabled >Change Plan</button>) :
                    (<button className='btn btn-success m-3'  onClick={planHandler} >Change Plan</button>)
                  }
                
                </div>
            </div>
        </div>
    )
}

export default Header;