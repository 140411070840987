import React from 'react'
import Header from './header';
import Sidebar from './sidebar';

function Layout({ children }) {
    return (
        <div>
            <Header />

            <div className='d-flex sidebarClass'>
                <Sidebar />
               {children}
            </div>
        </div>
    )
}

export default Layout;