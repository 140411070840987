import React, { useState, useEffect } from 'react';
import './popup.css';
import "../App.css";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import facebook from '../components/dash/images/facebook.png'
import google from '../components/dash/images/google.png'
import lakme from '../components/dash/images/lakeme.jpeg'

const Popups = ({arg:{user, setUser, handleSubmit}}) => {

  function addfields() {
    console.log("second");
    document.getElementById("modal2").style.display = "none";
    document.getElementById("modal1").style.display = "block";
  }

  const notify = () => {
    if(otp.submitOtp[0].length >= 4){
    toast.success('Successfully toasted!')
    console.log("fffff====>","sucess..");
  }else{
    toast.error("This didn't work.")
    console.log('ddd======>',"failed..");
  }
  };    

  const [otp, setOtp] = useState("")
  

  let [searchParams] = useSearchParams();
  const code = searchParams.get("code") ||  sessionStorage.getItem("token");
  const decoded = jwt_decode(code);
  const shopname =decoded.jwttoken ;
  


  const url = " https://otplogin.ens.enterprises/hello/check/otp"
  const url2 = " https://otplogin.ens.enterprises/data/get/shop"



//   const googleSubmit = async() =>{
//     // alert("cliked 10000")
//     try{
//         const response = await axios({

//             url:"https://otplogin.ens.enterprises/login/auth/google",

//             method:"GET",
//             headers:{
//                 'Content-Type':'application/json'
//             },
//         });
//         console.log("response",response)
//         alert("Success")
//     }catch(error){
//         console.log(error)
//     }
//  }

useEffect(() => {
  axios.post(url2, {
    shop: shopname,
  })
    .then(({ data }) => {
      const { popupheading,GoogleText, FBText , inputplaceholder, buttontext, backcolor, buttoncolor, buttoncolor2, buttoncolor3, togglebutton1, togglebutton2 } = data;
      // console.log("data------------------>", data)
      setUser({
        ...user,
        popupheading,
        inputplaceholder,
        buttontext,
        backcolor,
        buttoncolor,
        buttoncolor2,
        buttoncolor3,
        togglebutton1,
        togglebutton2,
        FBText,
        GoogleText
      });
    });
}, []);



  const otpSubmit = async (e) => {
    e.preventDefault();

   const { data } = await axios.post(url, {
      shop:shopname,
      number:user.mobileOtp[0]
    });  
    console.log( "which data----->" ,data);
    console.log( "user!imporatant data==>" ,user)
  }

  return (
    
      <form onSubmit={(e)=>{addfields(e);otpSubmit(e)}} className="modal2 " id='' novalidate>

        <div className="content1" style={user.backcolor ? {backgroundColor:user.backcolor} : {backgroundColor:'#000000a6'}}>
          {/* {' '} */}
          {/* <div className="header"> <img src='asset/logo.png' /> </div> */}
          <h2 className='heading-text' id='main-heading'>Preview OTP Validator</h2>
          <div>
           {/* <img style={{width:"100%"}} src={lakme} />  */}
           </div>
          <label for="validationCustom012" htmlFor="formGroupExampleInput" class="form-label" id='sub-heading'>{user.popupheading ? user.popupheading :"Dear guest, please sign up/sign in using your mobile number"}</label>
          <input type="number" class="form-control" id="validationCustom012" required placeholder={user.inputplaceholder ? user.inputplaceholder :'Enter your 10 digit Mobile Number'} name="mobileOtp" value={user.mobileOtp} onChange={(e)=>{setUser({...user, [e.target.name]:[e.target.value]})}}/>
          <div>
            <button
            type="submit" class="btn" id='btn-style2'
            style={user.buttoncolor ? {backgroundColor: user.buttoncolor} : {backgroundColor: 'rgb(122, 220, 180)'}}
            onClick={(e) => {
              // otpSubmit(e)
              // addfields()
              console.log(user.mobileOtp[0]);
            }}
          >
            {user.buttontext ? user.buttontext :"Get OTP"}
          </button>

          <div className="line-container">
                <div className="horizontal-line"></div>
                <span className="or-text"  style={user.backcolor ? {backgroundColor:user.backcolor} : {backgroundColor:''}} >or</span>
              </div>

          <a
            type="submit" class="btn" id='btn-style2'

            style={user.buttoncolor2 ? {backgroundColor: user.buttoncolor2} : {backgroundColor: 'rgb(122, 220, 180)'}}

            href='https://otplogin.ens.enterprises/auth/google'

          >
            <div className='socialButton' >
           <div>
           <img style={{width:"27px"}} src={google} /> 
           </div>
           <div>
           {user.GoogleText ? user.GoogleText :"Continue with Google"}
             </div>
           </div>
          </a>
          

          <a
            type="submit" class="btn" id='btn-style2'
            style={user.buttoncolor3 ? {backgroundColor: user.buttoncolor3} : {backgroundColor: 'rgb(122, 220, 180)'}}

            href='https://otplogin.ens.enterprises/auth/facebook'

          >
           <div className='socialButton' >
           <div>
           <img style={{width:"27px"}} src={facebook} /> 
           </div>
           <div>
           {user.FBText ? user.FBText :"Continue with Facebook"}
             
             </div>
           </div>
          </a>
        

        </div>
        
        </div>  
      </form>
     
  
  )
}

export default Popups;