
import AllRouter from "./routes";
//import "./App.css";
//import { GoogleLogin } from 'react-google-login';
//import { GoogleLogin } from 'react-google-login';

// const responseSuccessGoogle = (response) => {
//   console.log("8>>>>>>>>>>>>>",response);
// }

// const responseFailureGoogle = (response) => {
//   console.log("12>>>>>>>>>>>>>",response);
// }



const App = () => {
  // { <GoogleLogin
  //   clientId="985032455050-e0s7vlucke0l43qiodoitkcb02nu0f20.apps.googleusercontent.com"
  //   render={renderProps => (
  //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Login with Google</button>
  //   )}
  //   buttonText="Login with Google"
  //   onSuccess={responseSuccessGoogle}
  //   onFailure={responseFailureGoogle}
  //   cookiePolicy={'single_host_origin'}
  //   isSignedIn={true}
    
  // /> }
  return (
    <>
      <AllRouter />
      
      
    </>
  );
};

export default App;
