import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../App.css";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useSearchParams } from "react-router-dom";
import { Toaster } from 'react-hot-toast';


const FormData = ({ arg: { user, setUser, handleSubmit } }) => {


  // const notifyToaster = (e) => {
  //   toast.success('Successfully toasted!');
  //   console.log("toaster find")
  // };

  const notify1 = () => {
    console.log("mmmmmm=====>>", "formsubmit");
    toast.success("Toggle updated successfully",{
      autoClose:1500
    })
  };

  let [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const decoded = jwt_decode(code);
  const shopname = decoded.jwttoken;

  const url = " https://otplogin.ens.enterprises/data/get/shop";




  useEffect(() => {
    axios.post(url, {
      shop: shopname,
    })
      .then(({ data }) => {
        const { popupheading,GoogleText,FBText, inputplaceholder, buttontext, backcolor, buttoncolor, buttoncolor2, buttoncolor3, togglebutton1, togglebutton2 } = data;
        // console.log("data------------------>", data)
        setUser({
          ...user,
          popupheading,
          inputplaceholder,
          buttontext,
          backcolor,
          buttoncolor,
          buttoncolor2,
          buttoncolor3,
          togglebutton1,
          togglebutton2,
          GoogleText,
          FBText
        });
      });
  }, []);

  // console.log("isSwitchOn---", isSwitchOn)
  console.log("user---", user)


  return (
    <form onSubmit={(e) => { handleSubmit(e) }} className="needs-validation" novalidate>

      <div className="sub-container">
        <h2 className='form-heading'>OTP Validator</h2>
        <p>You can use OTP Validator by filling these inputs.</p>

        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
          <input type="text" className="form-control" name="popupheading" id="validationCustom011" placeholder="Change the heading of pop-up" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.popupheading} required />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
          <input type="text" className="form-control" name="inputplaceholder" id="validationCustom011" placeholder="Change the placeholder of input" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.inputplaceholder} required />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
          <input type="text" className="form-control text-dark" name='buttontext' id="validationCustom011" placeholder="Change the button text" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttontext} required />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex btn-textcolor justify-content-between">
            <label for="exampleInputurl" className="form-label picker-class">Change pop-up background color:</label>
            <div className="color-pick">
              <input type="color" id="exampleInputurl" name="backcolor" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.backcolor} />
            </div>
          </div></div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex btn-textcolor justify-content-between">
            <label for="exampleInputurlb" className="form-label picker-class">Change button color:</label>
            <div className="color-pick">
              <input type="color" id="exampleInputurlb" name="buttoncolor" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttoncolor} />
            </div>
          </div></div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex btn-textcolor justify-content-between">
            <label for="exampleInputurlb" className="form-label picker-class">Change google color:</label>
            <div className="color-pick">
              <input type="color" id="exampleInputurlb" name="buttoncolor2" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttoncolor2} />
            </div>
          </div></div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex btn-textcolor justify-content-between">
            <label for="exampleInputurlb" className="form-label picker-class">Change facebook color:</label>
            <div className="color-pick">
              <input type="color" id="exampleInputurlb" name="buttoncolor3" onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }} value={user.buttoncolor3} />
            </div>
          </div>
        </div>
         

        <div>
          <button type="submit" className="btn btn-success" id='btn-style'
            onClick={(e) => { notify1(e) }}>
            Success
          </button>
  <ToastContainer/>
        </div>
        {/* <div className='btm-section'>
                    <div>or sign in with</div>
                    <div>
                        <i className="bi bi-facebook fb-icon"></i>
                        <i className="bi bi-twitter twr-icon"></i>
                        <i className="bi bi-google g-icon"></i>
                    </div>
                </div> */}
      </div>
    </form>
  )
}

export default FormData;