import Home from "../pages/home";
import ProtectedRoute from "../pages/protected";
import Dashboard from "../pages/dashboard";
import UrlPage from "../pages/url";
import Main from "../components/dash/Main";
import Header from "../components/dash/header";
import Sidebar from "../components/dash/sidebar";
import Help from "../Page/help";
import Report from '../Page/report'
import Settings from "../Page/settings";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Integration from "../Page/integration";
import Document from "../components/dash/Document";
import NotFoundPage from "../pages/404";


const AllRouter = () => {

  const [inputValue, setInputValue] = useState("");


  return (
    <>
      <BrowserRouter>
        <Routes>
          
          <Route exact path="/" element={<Home inputValue = {inputValue} setInputValue={setInputValue} />} />
          {/* <Route exact path='/' element={<Home input={input} setInput={setInput} />} */}
          <Route exact path="/shopify" element={<UrlPage inputValue = {inputValue} />} />
          <Route exact path="/shopify" element={ <UrlPage inputValue={inputValue} />} /> 
          <Route exact path="/dashboard" element={ <ProtectedRoute><Dashboard /> </ProtectedRoute>} />  
          <Route exact path="/main" element={<ProtectedRoute> <Main /> </ProtectedRoute>} />  
          <Route exact path="/header" element={<ProtectedRoute> <Header /></ProtectedRoute>} />  
          <Route exact path="/sidebar" element={ <ProtectedRoute><Sidebar /></ProtectedRoute>} />  
          <Route exact path="/help" element={<ProtectedRoute> <Help /></ProtectedRoute>} />   
          <Route exact path="/plan" element={<ProtectedRoute> <Report /></ProtectedRoute>} />  
          <Route exact path="/settings" element={ <ProtectedRoute><Settings /></ProtectedRoute>} />  
          <Route exact path="/integration" element={<ProtectedRoute> <Integration/></ProtectedRoute>} />  
          <Route exact path="*" element={<NotFoundPage />} />
          <Route exact path="/document" element={ <ProtectedRoute><Document /></ProtectedRoute>} /> 
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default AllRouter;
