import React, { useState , useEffect} from 'react'
import appearance from '../dash/images2/appearance.svg';
import dashboard from '../dash/images2/dash.svg'
import integration from '../dash/images2/dashboard.svg';
import report from '../dash/images2/reports.svg';
import setting from '../dash/images2/settings.svg';
import help from '../dash/images2/help.svg'
import { Link } from 'react-router-dom';
import home from '../dash/images2/home.svg';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import Hamburger from 'hamburger-react'

function Sidebar() {

    var code = sessionStorage.getItem("token")
  const decoded = jwt_decode(code)
  const shopname = decoded.jwttoken;
  console.log("shopname===>",shopname)

    const [isOpen, setOpen] = useState(false)


    const toggleSidebar = () => {
        setOpen(!isOpen)
    }

    return (

        <div>
            <div>
                <Hamburger className='handleHamburger' onToggle={toggleSidebar} style={{ width: "220px", background: "#f6f6f7" }} />
            </div>
            <div className={` ${isOpen ? 'sideMenu2' : 'sideMenu'} `} >
                <ul className="ps-4 border border-1 ms-0 ulStyle" >

                    <li className='mt-3'>
                        <Link to={"/dashboard"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={home} className='ms-2'/>
                            <span className='ms-2'>
                                Dashboard
                            </span>
                        </Link>
                    </li>
                    <hr />
                    <li className='mt-3'>
                        <Link to={"/document"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={dashboard} />
                            <span className='ms-1'>
                                Document
                            </span>
                        </Link>
                        <hr />
                    </li>

                    <li className='mt-3'>
                        <Link to={"/main"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={appearance} />
                            <span className='ms-1'>
                                Appearance
                            </span>
                        </Link>
                    </li>
                    <hr />
                    <li className='mt-3'>
                        <Link to={"/Settings"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={setting} />
                            <span className='ms-1'>
                                Settings
                            </span>
                        </Link>
                    </li>

                    <hr />
                    <li className='mt-3'>
                        <Link to={"/plan"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={report} />
                            <span className='ms-1'>
                                Plan
                            </span>
                        </Link>
                    </li>
                    <hr />

                    <li className='mt-3'>
                        <Link to={"/integration"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={integration} />
                            <span className='ms-1'>
                                Integration
                            </span>
                        </Link>
                    </li>
                    <hr />

                    <li className='mt-3'>
                        <Link to={"/help"} style={{ textDecoration: "none", color: "gray" }}>
                            <img src={help} />
                            <span className='ms-1'>
                                Support
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;