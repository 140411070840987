import React, { useState , useEffect} from 'react';
import FormData from './formdata';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Popups from './popup';
import '../App.css';
import { useSearchParams } from "react-router-dom";
import Layout from './dash/layout';


const HomePage = () => {
  let [searchParams] = useSearchParams();
  const code = sessionStorage.getItem("token");
  const decoded = jwt_decode(code);
  const shopname = decoded?.jwttoken; 


  const [planstatus ,setPlanStatus] = useState("")

  useEffect(()=>{
      getPlanDetails()
    },[])
  
  
    const getPlanDetails = async() =>{
      const payload = {
        "shop" : shopname,
      }
       try{
        const response = await axios({
          url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
          method:"POST",
          headers:{
            "Content-Type": "application/json",
          },
          data:payload
        })
        if(response){
          console.log("respppppppppp",response.data.planName)
          setPlanStatus(response.data.planName)
        }
       }catch(error){
        console.log(error)
       }
    }



  const url = "/data/otp/settings";

  const [user, setUser] = useState({
    shop: shopname,
    popupheading: "",
    inputplaceholder: "",
    buttontext: "",
    backcolor: "",
    buttoncolor: "",
    buttoncolor2: "",
    buttoncolor3: "",
    togglebutton1: "",
    togglebutton2: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = await axios.post(url, {
      shop: shopname,
      popupheading: user.popupheading,
      inputplaceholder: user.inputplaceholder,
      buttontext: user.buttontext,
      backcolor: user.backcolor,
      buttoncolor: user.buttoncolor,
      buttoncolor2: user.buttoncolor2,
      buttoncolor3: user.buttoncolor3,
      togglebutton1: user.togglebutton1,
      togglebutton2: user.togglebutton2
    });
    console.log(data);
    console.log(user)
  }
  return (
    <Layout>
      {
        (planstatus === "Free trial" || planstatus === "Basic" ) ? (
          <>
          <div className='container p-4 MainDiv'>
            <div className=' row '>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="sub-container" >
                  <h2 className='form-heading'>OTP Validator</h2>
  
                  {/* <div className="col-lg-12 col-md-12 col-sm-12">
                    <h4>1. Appearance Page</h4>
                    <p>Customize visual elements with real-time preview.</p>
  
                    <h4>2. Settings Page</h4>
                    <p>Configure login options with toggler buttons.</p>
  
                    <h4>3. Plan Component</h4>
                    <p>Offers a 7-day free trial. Subsequently, a subscription fee of $9.99/month is required to access premium features.</p>
  
                    <h4>4. Integration Page</h4>
                    <p>Select preferred verification method: Fast2SMS or Gupshup.</p>
  
                    <h4>5. Support Page</h4>
                    <p>Direct channel to seek assistance and submit issues to the Shopify app team.</p>
                  </div> */}
                  <div className="row sales-report">
                    <div className="col-md-12 p-3 dashboard-help">
                      <h4><b>How it Works ?</b></h4>
                      <p>Follow the steps below ..</p>
                    </div>
                    <div className="col-md-12 dash-points_index">
                      <ul style={{ fontFamily: 'Thasadith', fontSize: '14px' }}>
                        <li className="box_shodow">
                          <span style={{ color: 'purple', fontSize: '16px' }}> * Appearance Page: </span>
                          Allows users to customize app visuals.
                          Section 1: Modify button and background colors. 
                          Section 2: Live preview for real-time changes.
                        </li>
                        <br></br>
                        <li className="box_shodow">
                          <span style={{ color: 'purple', fontSize: '16px' }}> * Settings Page: </span>
                          Configure login options with toggler buttons.
                          <p style={{ color: 'red', fontWeight: '800' }}> <span> Note :</span> Configure login options via toggler buttons.
                            Toggler 1: Enable/disable OTP login.
                            Toggler 2: Enable/disable social login. </p>
                          {/* <p style={{ fontWeight: '900' }}>If you have more than 11000 zipcodes, just break them into multiple files and import them, but remember each CSV file should not contain more than 11000 zipcodes.</p> */}
                        </li>
                        <br></br>
                        <li className="box_shodow">
                          <span style={{ color: 'purple', fontSize: '16px' }}> * Plan: </span>
                          Offers subscription with 7-day trial.
                          $9.99/month after trial.
                        </li>
                        <br></br>
                        <li className="box_shodow">
                          <span style={{ color: 'purple', fontSize: '16px' }}> * Integration Page: </span>
                          Select preferred user verification method.
                          Options: Fast2SMS or Gupshup.
  
                        </li>
                        <br></br>
                        <li className="box_shodow">
                          <b style={{ color: '#1a1a1a', fontSize: '16px' }}> * Support Page : </b>
                          <span style={{ color: 'purple', fontSize: '15px' }}>Seek assistance from Shopify app team.
                            Submit issues or queries directly.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <Popups arg={{ user, setUser }} />
              </div>
            </div>
          </div>
        </>
        ):
        (!planstatus) ?  ( <h5 style={{textAlign:"center"}} >Loading...</h5> ) :
        (
          <div  className='userDiv'><h2>This is only accessed by our paid users.</h2></div>
        )
      }
    
    </Layout>
  )
}

export default HomePage