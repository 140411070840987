import React from 'react';
import HomePage from '../../components/homepage';
import "../../App.css";
import jwt_decode from "jwt-decode";
import { useSearchParams } from "react-router-dom";

const Dashboard = () => {
    let [searchParams] = useSearchParams();
    const code = searchParams.get("code") || sessionStorage.getItem("token")
    if(code){
    sessionStorage.setItem("token",code)
    }
    // const decoded = jwt_decode(code) || sessionStorage.getItem("token") ;
    // const shopname =decoded?.jwttoken ;
    return (
      <React.Fragment>         
          <HomePage />
          {/* <div className='text-bm '>
              <h6>©2022 ENS Enterprises Private Limited. All Rights Reserved.</h6>
          </div> */}
      </React.Fragment>
    )
}

export default Dashboard;