import React, { useState , useEffect } from 'react'
import Layout from '../components/dash/layout'
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Toaster } from 'react-hot-toast';



const Integration = () => {
    var code = sessionStorage.getItem("token")
    const decoded = jwt_decode(code)
    const shopname = decoded.jwttoken;
    console.log("shopname===>", shopname)

    const initialData = {
        shop: shopname,
        payload_name: "fast2sms",
        APIKEY: ""
    }

    const googleIntialData = {
        shop: shopname,
        payload_name:"google",
        client_id:"",
        client_secret:""
    }

    const facebookIntialData = {
        shop: shopname,
        payload_name:"facebook",
        client_id:"",
        client_secret:""
    }


    const GupsupInitialData = {
        shop: shopname,
        payload_name: "gupshup",
        userid: "",
        password: "",
        message_body_template: ""
    }

    const [SMSoption, setSMSoption] = useState("")
    // const [socialoption , setSocialOption] = useState("google")

    // const [googleBorderColor, setgoogleBorderColor] = useState('rgb(122, 220, 180)');
    // const [facebookBorderColor,setFacebookBorderColor] = useState('')
    
    const [fast2smsBorderColor, setFast2smsBorderColor] = useState('');
    const [gupsupBorderColor, setGupsupBorderColor] = useState('');

    const [inteData,setInteData] = useState('')
    console.log("lineno 33333333333333======>", inteData)


    const[googleData,setGoogleData] = useState(googleIntialData)
    const[facebookData , setFacebookData] = useState(facebookIntialData)


    const [formData, setFormData] = useState(initialData);
    const [gupsupData, setGupsupData] = useState(GupsupInitialData)

    const [planstatus ,setPlanStatus] = useState("")

    useEffect(()=>{
        getPlanDetails()
      },[])
    
    
      const getPlanDetails = async() =>{
        const payload = {
          "shop" : shopname,
        }
         try{
          const response = await axios({
            url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
            method:"POST",
            headers:{
              "Content-Type": "application/json",
            },
            data:payload
          })
          if(response){
            console.log("respppppppppp",response.data.planName)
            setPlanStatus(response.data.planName)
          }
         }catch(error){
          console.log(error)
         }
      }

    const onOptionChange = e => {
        setSMSoption(e.target.value)
        let value = e.target.value;
        if (value == "fast2sms" ) {
            setFast2smsBorderColor('rgb(122, 220, 180)');
            setGupsupBorderColor('');
        } else if (value == "gupshup") {
            setGupsupBorderColor('rgb(122, 220, 180)');
            setFast2smsBorderColor('');
        }
    }
    useEffect(()=>{
        getGoogleData()
        integrationData()
    },[])
     console.log("SMSoption--------->",SMSoption)

    console.log("SMSoption------->",SMSoption)
    const integrationData= async (e) =>{
        try {
            const response = await axios({
                url: `https://otplogin.ens.enterprises/hello/pay/getShopPayload?shop=${shopname}`,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {

               console.log("getting integrtion data or not", response.data.data[0].payload_name )
               if(response.data.data[0].payload_name == "fast2sms"){
                setFormData({
                    APIKEY:response.data.data[0].APIKEY ,
                   }) 
                   setSMSoption("fast2sms")
                   setFast2smsBorderColor('rgb(122, 220, 180)');
                   setGupsupBorderColor('');
               }
               else if(response.data.data[0].payload_name=="gupshup"){
                setGupsupData({
                    ...gupsupData,
                    userid: response.data.data[0].userid,
                    password : response.data.data[0].password,
                    message_body_template : response.data.data[0].message_body_template
                })
                setSMSoption("gupshup")
                setGupsupBorderColor('rgb(122, 220, 180)');
                setFast2smsBorderColor('')
               }

            }
        } catch (error) {
            console.log(error)
        }
    }


    const getGoogleData= async (e) =>{
        try {
            const response = await axios.get(`https://otplogin.ens.enterprises/social/getSocialPayload?shop=${shopname}`);
        
            if (response.status === 200) {
                const data = response.data.data;
        
                // Filter data for Google payload
                const googlePayload = data.find(item => item.payload_name === "google");
                if (googlePayload) {
                    setGoogleData({
                        client_id: googlePayload.client_id,
                        client_secret: googlePayload.client_secret
                    });
                    console.log("Google data:", googlePayload);
                } else {
                    console.log("Google payload not found");
                }
        
                // Filter data for Facebook payload
                const facebookPayload = data.find(item => item.payload_name === "facebook");

                if (facebookPayload) {
                    setFacebookData({
                        client_id: facebookPayload.client_id,
                        client_secret: facebookPayload.client_secret
                    });
                    console.log("Facebook data:", facebookPayload);
                } else {
                    console.log("Facebook payload not found");
                }
            }
        } catch (error) {
            console.log(error);
        }
        
    }


    const googleSubmit= async (e) =>{
        e.preventDefault()
        console.log("googledaata===>",googleData)
        try {
            const response = await axios({
                url: "https://otplogin.ens.enterprises/social/savepayload",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }, data: googleData
            })
            if (response.status === 200) {
                toast.success("Saved Successfully", {
                    autoClose: 1500
                })
            }
        } catch (error) {
            toast.error("Something went wrong",{
                autoClose:1500
            })
            console.log(error)
        }
    }

    const facebookSubmit= async (e) =>{
        e.preventDefault()
        console.log("facebookdata===>",facebookData)
        try {
            const response = await axios({
                url: "https://otplogin.ens.enterprises/social/savepayload",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }, data: facebookData
            })
            if (response.status === 200) {
                toast.success("Saved Successfully", {
                    autoClose: 1500
                })
            }
        } catch (error) {
            toast.error("Something went wrong",{
                autoClose:1500
            })
            console.log(error)
        }
    }
    // const facebookSubmit= async (e) =>{
    //     e.preventDefault()
    //     console.log("facebookdata===>",facebookData)
    //     try {
    //         const response = await axios({
    //             url: "https://otplogin.ens.enterprises/social/savepayload",
    //             method: "POST",
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }, data: facebookData
    //         })
    //         if (response.status === 200) {
    //             toast.success("Saved Successfully", {
    //                 autoClose: 1500
    //             })
    //         }
    //         // setFacebookData(facebookIntialData)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const gupsupFormSubmit = async (e) => {
        e.preventDefault()
        console.log("gupsupdata submitted with data:", gupsupData)
        try {
            const response = await axios({
                url: "https://otplogin.ens.enterprises/hello/save/payload",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }, data: gupsupData
            })
            if (response.status === 200) {
                toast.success("Saved Successfully", {
                    autoClose: 1500
                })
            }
            // setGupsupData(GupsupInitialData)
        } catch (error) {
            console.log(error)
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted with data:', formData);
        try {
            const response = await axios({
                url: "https://otplogin.ens.enterprises/hello/save/payload",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }, data: formData
            })

            if (response.status === 200) {
                toast.success("Saved Successfully", {
                    autoClose: 1500
                })
            }
            // setFormData(initialData);
        } catch (error) {
            console.log(error)
        }

    };

    return (
        <div>
            <Layout>
                {
                    (planstatus === "Free trial" || planstatus === "Basic" ) ? (
                        <div className='container p-4' style={{ background: "#eae8f0" }}>
                        <div style={{ display: "flex", justifyContent: "center" }} >
                            <h2 className='mb-4' style={{ color: "rgb(122, 220, 180)", fontWeight: "800" }} >Integration Page</h2>
                        </div>
                            
                        <h4 style={{ color: "rgb(122, 220, 180)", fontWeight: "800" }}>SMS setting integration</h4>
                        {/* sub container 2 & 3 is in App.css */}
                        <div className='row'>
                            
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <form onSubmit={(e) => { handleFormSubmit(e) }} className="needs-validation " novalidate>
                                    <div className="sub-container2" style={{ border: SMSoption === 'fast2sms' ? `5px solid ${fast2smsBorderColor}` : '2px gray' }} >
                                        <div style={{ display: "flex", gap: "20px" }} >
                                            <div>
                                                <h2 className='form-heading'>Fast2sms</h2>
                                            </div>
                                            <div>
                                                <input style={{ marginTop: "13px" }}
                                                    type="radio"
                                                    name="SMSoption"
                                                    value="fast2sms"
                                                    id="fast2sms"
                                                    checked={SMSoption === "fast2sms"}
                                                    onChange={onOptionChange}
                                                />
                                            </div>
                                        </div>
                                        <p>You can use Fast2sms OTP by filling this input.</p>
    
                                        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                                            <input type="text" className="form-control" name="APIKEY" id="validationCustom011" placeholder="APIKEY" onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }} value={formData.APIKEY} required />
                                        </div>
    
                                        <div>
                                            <button type="submit" className="btn btn-success" id='btn-style' disabled={SMSoption !== "fast2sms"}>
                                                Success
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12"> 
    
    
                                <form onSubmit={(e) => { gupsupFormSubmit(e) }} className="needs-validation" novalidate>
    
                                    <div className="sub-container3 "
                                        style={{ border: SMSoption === 'gupshup' ? `5px solid ${gupsupBorderColor}` : '2px gray' }}
                                    >
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <div>
                                                <h2 className='form-heading'>Gupshup</h2>
                                            </div>
    
                                            <div>
                                                <input style={{ marginTop: "13px" }}
                                                    type="radio"
                                                    name="SMSoption"
                                                    value="gupshup"
                                                    id="gupshup"
                                                    checked={SMSoption === "gupshup"}
                                                    onChange={onOptionChange}
                                                />
                                            </div>
                                        </div>
                                        <p>You can Gupsup OTP by filling these inputs.</p>
    
                                        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                                            <input type="text" className="form-control" name="userid" id="validationCustom011" placeholder="User Id" onChange={(e) => { setGupsupData({ ...gupsupData, [e.target.name]: e.target.value }) }} value={gupsupData.userid} required />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                                            <input type="text" className="form-control" name="password" id="validationCustom011" placeholder="Password" onChange={(e) => { setGupsupData({ ...gupsupData, [e.target.name]: e.target.value }) }} value={gupsupData.password}  required />
                                        </div>
    
                                        <div className="col-lg-12 col-md-12 col-sm-12" for="validationCustom011" htmlFor="formGroupExampleInput">
                                            <input type="text" className="form-control" name="message_body_template" id="validationCustom011" placeholder="Message body template " onChange={(e) => { setGupsupData({ ...gupsupData, [e.target.name]: e.target.value }) }} value={gupsupData.message_body_template}  required />
                                        </div>
    
                                        <div>
                                            <button type="submit" className="btn btn-success" id='btn-style' disabled={SMSoption !== 'gupshup'} >
                                                Success
                                            </button>
    
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
    
    {/*--------------------------social_login_integration---------------------------------------------*/}
    
    
                        <h4 style={{ color: "rgb(122, 220, 180)", fontWeight: "800" }} >Social login integration</h4>
    
                        <div className='row'>
                            
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <form onSubmit={(e) => { googleSubmit(e) }} className="needs-validation " novalidate>
                                    <div className="sub-container2" style={{ border: '5px solid rgb(122, 220, 180)'}} >
                                        <div style={{ display: "flex", gap: "20px" }} >
                                            <div>
                                                <h2 className='form-heading'>Google</h2>
                                            </div>
                                            <div>
                                                {/* <input style={{ marginTop: "13px" }}
                                                    type="radio"
                                                    name="socialoption"
                                                    value="google"
                                                    id="google"
                                                    checked={socialoption === "google"}
                                                    onChange={socialOptionChange}
                                                /> */}
                                            </div>
                                        </div>
                                        <p>You can use google social login by filling this input.</p>
    
                                        <div className="col-lg-12 col-md-12 col-sm-12" >
                                            <input type="text" className="form-control" name="client_id"  placeholder="ClientId" onChange={(e) => { setGoogleData({ ...googleData, [e.target.name]: e.target.value }) }} value={googleData.client_id}  required />
                                        </div>
    
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <input type="text" className="form-control" name="client_secret"  placeholder="ClientSecret" onChange={(e) => { setGoogleData({ ...googleData, [e.target.name]: e.target.value }) }} value={googleData.client_secret} required />
                                        </div>
    
                                        <div>
                                            <button type="submit" className="btn btn-success" id='btn-style' >
                                                Success
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
    
                            <div class="col-lg-6 col-md-6 col-sm-12">
                            <form onSubmit={(e) => { facebookSubmit(e) }} className="needs-validation" novalidate>
                                    <div className="sub-container3 "
                                        style={{ border: '5px solid rgb(122, 220, 180)'}}
                                    >
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <div>
                                                <h2 className='form-heading'>Facebook</h2>
                                            </div>
    
                                            <div>
                                                {/* <input style={{ marginTop: "13px" }}
                                                    type="radio"
                                                    name="socialoption"
                                                    value="facebook"
                                                    id="facebook"
                                                    checked={socialoption === "facebook"}
                                                    onChange={socialOptionChange}
                                                /> */}
                                            </div>
                                        </div>
                                        <p>You can use facebook social by filling these inputs.</p>
    
                                        <div className="col-lg-12 col-md-12 col-sm-12" >
                                            <input type="text" className="form-control" name="client_id" placeholder="ClientID" onChange={(e) => { setFacebookData({ ...facebookData, client_id: e.target.value }) }} value={facebookData.client_id} required />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12"  >
                                            <input type="text" className="form-control" name="client_secret"  placeholder="ClientSecret" onChange={(e) => { setFacebookData({ ...facebookData, [e.target.name]: e.target.value }) }} value={facebookData.client_secret}  required />
                                        </div>
    
                                        <div>
                                            <button type="submit" className="btn btn-success" id='btn-style'  >
                                                Success
                                            </button>
    
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    ):
                    (!planstatus) ?  ( <h5 style={{textAlign:"center"}} >Loading...</h5> ) :
                    (
                        <div className='userDiv'><h2>This is only accessed by our paid users.</h2></div>
                    )
                }
                
                <ToastContainer />
                <Toaster />
            </Layout>
        </div>
    )
}

export default Integration
