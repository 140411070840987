import React, {useState , useEffect} from 'react'
import Layout from '../components/dash/layout'
import axios from 'axios'
import './help.css'
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify'

function  Help() {

  var code = sessionStorage.getItem("token")
  const decoded = jwt_decode(code)
  const shopname = decoded.jwttoken;
  console.log("shopname===>",shopname)

  const [support, setSupport] = useState('')

  const [planstatus ,setPlanStatus] = useState("")

  useEffect(()=>{
      getPlanDetails()
    },[])
  
  
    const getPlanDetails = async() =>{
      const payload = {
        "shop" : shopname,
      }
       try{
        const response = await axios({
          url: 'https://otplogin.ens.enterprises/OTPplan/getPlanDetail',
          method:"POST",
          headers:{
            "Content-Type": "application/json",
          },
          data:payload
        })
        if(response){
          console.log("respppppppppp",response.data.planName)
          setPlanStatus(response.data.planName)
        }
       }catch(error){
        console.log(error)
       }
    }

  const handleChange = (e) =>{
       setSupport(e.target.value)
       console.log("supproat",support)
  }

  const supportMessage = async(e) =>{
    e.preventDefault()
    const payload = {     
        shop: shopname ,
        message: support
    }
    console.log("ispayloadis Right===",payload)
    try{
      const response = await axios({

        url:"https://otplogin.ens.enterprises/support/supportMail",

        method:"POST",
        headers:{
          'Content-Type':'application/json'
      },data: payload
      })
      if(response.data.message== "flag1"){
        toast("Message send successfully",{
          autoClose:1500
        })
      }
      
      setSupport('')
    }catch(error){
      console.log(error)
    }
  }

  return (

    <div>
    <Layout>
    {(planstatus === "Free trial" || planstatus === "Basic" ) ? 
    (
      <div className='mainBlock'>
      <h3 className='supportHeading' style={{color:"rgb(122, 220, 180)",fontWeight:"600"}} >Support</h3>
      <div className='minBlock'>
        <div className='minBlock2'>


          <div className='ContentDiv'>
            <div className='content'>
              <h3 style={{ fontSize: "28px", fontWeight: "800",color:"rgb(122, 220, 180)" }}> Contact support for OTP varification app:</h3>
              <div className='contentSeprator'>
                <p style={{ fontSize: "16px", fontWeight: "400" , color:"rgb(122, 220, 180)"}}>By ENS Enterprises Pvt, Ltd.</p>
                <p style={{ fontSize: "16px", fontWeight: "400" ,color:"rgb(122, 220, 180)"}} className='description' >ENS Enterprises will be able to address any questions or issues you have with the OTP varification app.</p>
              </div>
            </div>
          </div>
         </div>

        <div style={{margin:"0 1rem"}}>
        <div className="container-fluid mt-2 ">
          <form>
            <div className="form-group">

              <textarea className="form-control " id="exampleTextarea" rows="5" placeholder='Write Something' value={support} onChange={handleChange} ></textarea>
            </div>
            <p style={{fontSize:"18px",fontWeight:"500",marginTop:"15px",color:"rgb(122, 220, 180)",fontWeight:"800"}}>Minimum 30 characters !</p>
            <button type="submit" className="sendButton mt-2" onClick={supportMessage} >Send</button>
          </form>
        </div>
      </div>
      </div>
    </div>
    ) : 
    (!planstatus) ?  ( <h5 style={{textAlign:"center"}} >Loading...</h5> ) :
    
    (
      <div className='userDiv'><h2>This is only accessed by our paid users.</h2></div>
    )}
      <ToastContainer />
    </Layout>
    </div>
  )
}

export default  Help