import React, { useEffect } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Home = ({ inputValue, setInputValue }) => {
  const navigate = useNavigate();

  // useEffect(()=>{
  //     (async()=>{
  //         const { data } =  await axios({


    //            url:" https://localhost:5002/hello/check/otp",


  //            method:"post"
  //          });
  //          console.log("this data => ", data);
  //      })();
  // }, [])


    const changeValue = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue.replace('https://', '').replace('/',''));
    }
    // const handleInstall = async () => {
    //     // console.log({ input })
    //     const response = await axios.get('https://localhost:5002/shopify', {
    //       params: {shop:inputValue }
    //     });
    //     console.log(response.status)
    //     if (response.status === 200) {
    //       window.location.href = response.data;
    //     }

    //   }
    // console.log(response.status);
    // if (response.status === 200) {
    //   window.location.href = response.data;
    // }

  const handleSubmit = (e) => {
      e.preventDefault()
    console.log(inputValue);

  }
  // console.log(inputValue);

  return (
    <div className="homepage_all">
      <div className="col-lg-8 col-md-8 col-sm-6 home_pageside text-dark bg-light">
        <form
          onSubmit={handleSubmit}
          className="needs-validation form-padding"
          novalidate
        >
          <div class="mb-3">
            <label
              for="validationCustom011"
              className="text-start label_class"
              HtmlFor="formGroupExampleInput"
            >
              <h5 className="text-change">Enter Shop URL</h5>
            </label>
            <input
              type="url"
              id="validationCustom011"
              required
              class="form-control"
              placeholder="ShopName.myshopify.com"
              onChange={changeValue}
            />
            <div class="invalid-tooltip">Please choose a ShopURL.</div>
            <div id="textHelp" class="form-text">
              We'll never share your Shop URL with anyone else.
            </div>
          </div>
          <div class="mb-3 form-check">
            <div className="row g-2">
              <div className="d-grid gap-2 col-6 mx-auto">
                <button
                  type="submit"
                  onClick={()=>{ inputValue && navigate('/shopify')}} value="Install"
                  className="btn btn-success"
                  id="btn-style"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>{" "}
    </div>
  );
};

export default Home;